<template>
<div>
  <b-container class="bg-white py-4">
    <b-row class="mb-4" v-if="!permissions.createMigration">
      <b-col>
        No access
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permissions.createMigration">
      <b-col>
          <h2 class="my-4">Server message</h2>
          <div><strong>{{message}}</strong></div>
          <h2 class="my-4">Knex migrations</h2>
          <h4 class="my-4">Current version</h4>
          <p>Check current verstion of the migration</p>
          <div><b-button variant="primary" v-on:click="currentversion">Check current version</b-button></div>
          <h4 class="my-4">Migrate:latest</h4>
          <p>Perform database mgiration to the latest migration - knex migrate:latest</p>
          <div><b-form-input v-model="testMigrateLatest" placeholder="Write: migrate_latest"></b-form-input></div>
          <div v-if="testMigrateLatest==='migrate_latest'"><b-button variant="danger" v-on:click="latest">Migrate latest</b-button></div>
          <h3 class="my-4">Server response</h3>
          <div v-show="busy"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
          <h2>Scrape log clean up</h2>
          <div><b-button variant="primary" v-on:click="cleanScrapelog">Clean Scrapelog</b-button></div>
          <h2 class="mt-4">Delete news by id and source</h2>
          <div><b-form-input v-model="newsSource" placeholder="source"></b-form-input></div>
          <div><b-form-input v-model="newsStartId" placeholder="Id equal or greater"></b-form-input></div>
          <div><b-form-input v-model="newsEndId" placeholder="Id equal or smaller"></b-form-input></div>
          <div><b-button variant="primary" v-on:click="deleteNews">Delete items</b-button></div>
          <h2 class="mt-4">Rename news</h2>
          <div><b-form-input v-model="currentNews.publisher" placeholder="current publisher"></b-form-input></div>
          <div><b-form-input v-model="currentNews.source" placeholder="current source"></b-form-input></div>
          <div><b-form-input v-model="currentNews.type" placeholder="current type"></b-form-input></div>
          <div><b-form-input v-model="newNews.publisher" placeholder="new publisher"></b-form-input></div>
          <div><b-form-input v-model="newNews.source" placeholder="new source"></b-form-input></div>
          <div><b-form-input v-model="newNews.type" placeholder="new type"></b-form-input></div>
          <div><b-button variant="primary" v-on:click="renameNews">Rename news</b-button></div>
          <h2 class="mt-4">Assign ugroup id to controls</h2>
          <div><b-form-input v-model="ugroupid" placeholder="ugroup id"></b-form-input></div>
          <div><b-button variant="primary" v-on:click="assignControls">Assign ugroup</b-button></div>
          <h2 class="mt-4">ESG Metrics - Update metrics status</h2>
          <div>
            <p>Updates workflow to Aproved for all metrics with 'workflow_created_by' = null - typically after a bulk import</p>
            <b-button variant="primary" v-on:click="updateMetricsStatus">update metrics status</b-button>
          </div>
          <h2 class="mt-4">ESG Metrics - Update data elements status</h2>
          <div>
            <p>Updates workflow to Aproved for all data elements with 'workflow_created_by' = null - typically after a bulk import</p>
            <b-button variant="primary" v-on:click="updateDataElementsStatus">update data elements status</b-button>
          </div>
          <div>
            <b-button variant="primary" v-on:click="updateTaxonomySequence">update taxonomy sequence</b-button>
          </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '../libs/accesscontrol'

export default {
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permissions.createMigration = ac.can(this.user.acgroups).createAny('migration').granted
    if (!this.permissions.createMigration) {
      this.$router.push({ name: 'Start' })
    }
  },
  data () {
    return {
      busy: false,
      currentNews: {
        publisher: '',
        source: '',
        type: ''
      },
      message: '',
      newNews: {
        publisher: '',
        source: '',
        type: ''
      },
      newsEndId: null,
      newsSource: null,
      newsStartId: null,
      permissions: {},
      ugroupid: null,
      testMigrateLatest: '',
      topic: null
    }
  },
  methods: {
    updateDataElementsStatus: async function () {
      this.busy = true
      try {
        const response = await this.$Amplify.API.get('cosmos', '/dataelements/updatestatus')
        this.message = response.msg
      } catch (e) {
        this.message = e
      }
      this.busy = false
    },
    updateMetricsStatus: async function () {
      this.busy = true
      try {
        const response = await this.$Amplify.API.get('cosmos', '/metrics/updatestatus')
        this.message = response.msg
      } catch (e) {
        this.message = e
      }
      this.busy = false
    },
    assignControls: async function () {
      this.busy = true
      try {
        const response = await this.$Amplify.API.get('cosmos', `/assigncontrols/${this.ugroupid}`)
        this.message = response.msg
      } catch (e) {
        this.message = e
      }
      this.busy = false
    },
    cleanScrapelog: async function () {
      this.busy = true
      try {
        await this.$Amplify.API.get('cosmos', '/scrapelog/cleandb')
        this.message = 'Scrapelog cleaned'
      } catch (e) {
        this.message = e
      }
      this.busy = false
    },
    currentversion: async function () {
      this.busy = true
      try {
        let apiName = 'cosmos'
        let path = '/knex/migrate/currentversion'
        let response = await this.$Amplify.API.get(apiName, path)
        this.message = response
      } catch (e) {
        this.message = e
      }
      this.busy = false
    },
    deleteNews: async function () {
      this.busy = true
      try {
        let apiName = 'cosmos'
        let path = `/news/source/${this.newsSource}/startid/${this.newsStartId}/endid/${this.newsEndId}`
        const response = await this.$Amplify.API.del(apiName, path)
        this.message = response
      } catch (e) {
        this.message = e
      }
      this.busy = false
    },
    latest: async function () {
      this.busy = true
      try {
        let apiName = 'cosmos'
        let path = '/knex/migrate/latest'
        await this.$Amplify.API.get(apiName, path)
        this.testMigrateLatest = ''
        this.message = 'Migration done'
      } catch (e) {
        this.message = e
      }
      this.busy = false
    },
    renameNews: async function () {
      this.busy = true
      try {
        const params = {
          body: {
            current: this.currentNews,
            new: this.newNews
          }
        }
        const results = await this.$Amplify.API.post('cosmos', '/news/renamenews', params)
        this.message = 'Renaming done. Affected row: ' + results.msg
      } catch (e) {
        this.message = e
      }
      this.busy = false
    },
    updateTaxonomySequence: async function () {
      this.busy = true
      try {
        await this.$Amplify.API.get('cosmos', '/taxonomy/updateIdSeq')
        this.message = 'Scrapelog cleaned'
      } catch (e) {
        this.message = e
      }
      this.busy = false
    }
  }
}
</script>

<style>
</style>
